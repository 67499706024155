
import React, { useEffect, useState } from 'react';
import { Modal, Button } from '@themesberg/react-bootstrap';
import axios from "../../api/index"
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import valid from "../images/valid.png"
import AccordionComponent from '../../components/AccordionComponent';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

export default ({setShowBuy, ShowBuy, isModal}) => {
  const axiosPrivate = useAxiosPrivate()
  const [isLoading, setIsLoading] = useState(false)
  const [errorValue, setErrorValue] = useState("")
  const { auth, setAuth } = useAuth()
  const [ currency, setCurrency ] = useState("")
  const handleClose = () => setShowBuy({});
  const [ methodPayment, setMethodPayment] = useState(false)
  const [message, setMessage] = useState({ value: null, type: null });
  // const createOrder = (e) => {
  //     const create = async () => {
  //       setIsLoading(true)
  //       await axiosPrivate.post("create-order", { package_id: ShowBuy.id, currency }).then((r) => {
  //         if(r.status === 201) {
  //           setErrorValue("Being redirected...")
  //           openWindow(r.data.payment_url, "_blank")
  //           setTimeout(() => {
  //             handleClose()
  //           }, 2500)
  //         } else {
  //           setErrorValue("An error occured.")
  //           setTimeout(() => setErrorValue(""), 1800)
  //         }
  //         setIsLoading(false)
  //       }).catch((err) => {
  //         setErrorValue("An error occured.")
  //         setTimeout(() => setErrorValue(""), 1800)
  //         setIsLoading(false)
  //       })
  //     }
  //     if(["LTC", "BTC", "BNB", "DASH", "DOGE", "BCH"].includes(currency)) {
  //       create()
  //     } else {
  //       alert("Please select a currency.")
  //     }
    
  // }
const createOrder = async (e) => {
  if(!methodPayment || !["credit", "crypto"].includes(methodPayment)) return alert("Please a payment method.");
  if(methodPayment === "credit") {
    console.log('stripe redirect')
  }
  if (methodPayment === "crypto" && ["LTC", "BTC", "BNB", "DASH", "DOGE", "BCH"].includes(currency)) {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post("create-order", { package_id: ShowBuy.id, currency, methodPayment });
      if (response.status === 201) {
        setErrorValue("Being redirected...");
        setTimeout(() => window.location.href = response.data.payment_url, 2500)
      } else {
        setErrorValue("An error occurred.");
        setTimeout(() => setErrorValue(""), 1800);
      }
    } catch (error) {
      setErrorValue("An error occurred.");
      setTimeout(() => setErrorValue(""), 1800);
    }
    setIsLoading(false);
  } else {
    alert("Please select a currency.");
  }
};

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  "currency": "EUR",
  "enable-funding": "card",
  "disable-funding": "paylater,venmo",
  "data-sdk-integration-source": "integrationbuilder_sc",
};

  return (
    <Modal as={Modal.Dialog} centered show={!!Object.keys(ShowBuy).length} onHide={() => {}}>
        <Modal.Header>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
        {Number(auth.maxCurrent) > 0 && Number(auth.maxCurrent) !== Number(ShowBuy.advantages.find((v) => v.includes("Concurrent")).match(/\d+/g)?.map(Number)) ?
          <p>You can't upgrade your package with an another where the concurrent connections allowed doesn't match. If you need any readjustement regarding your package, please contact us on <a href={process.env.REACT_APP_DISCORD_URL} target="_blank">Discord</a></p>
        :
        <div className="d-flex flex-column align-items-center">
        <span style={{ fontSize: "2em" }}>{ShowBuy.name}</span>
        <span style={{ fontSize: "2em" }}>$ {ShowBuy.price} / {ShowBuy.period}</span>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "2%" }}>
        {ShowBuy.advantages.map((v, i) => {
          return (<span style={{ fontSize: "1.2em", marginTop: "2%" }} key={i}> <img width={24} height={24} src={valid} /> {v}</span>)
        })}
        </div>
        <AccordionComponent
          isPackage={true}
          setMethodPayment={setMethodPayment}
          data={[
            // {
            //   id: 1,
            //   eventKey: "credit",
            //   title: "Credit Card",
            //   description: 'Stripe (Visa/MasterCard...)',
            //   isPackage: true            
            // },
            {
              id: 2,
              eventKey: "crypto",
              title: "Cryptocurrency",
              description: <select defaultValue={!currency ? 'DEFAULT' : currency} style={{ width: "50%", marginTop: "2%", borderRadius: "5px", padding: "0.4em" }} onChange={(e) => setCurrency(e.target.value)}>
              <option value="DEFAULT" disabled>Select a currency </option>
              <option value="LTC">LTC - Litecoin</option>
              {(ShowBuy.period === "Annualy" || ShowBuy.price > 11) && <option value="BTC">BTC - Bitcoin</option>}
              <option value="BNB">BNB - Binance Smart Chain</option>
              <option value="BCH">BCH - Bitcoin Cash</option>
              <option value="DOGE">Doge - Doge Coin</option>
              <option value="DASH">Dash - Dash coin</option>
              {/* <option value="TCN">TCN - Test Coin</option> */}
            </select>,
            isPackage: true
            },
            {
              id: 3,
              eventKey: "paypal",
              title: "Paypal/Credit Card",
              description: <><PayPalScriptProvider options={initialOptions}>
              <PayPalButtons
                style={{
                  shape: "rect",
                  layout: "vertical",
                }}
                createOrder={async (data, actions) => {
                  try {
                    const res = await axiosPrivate.get('/rf-tk')
                    const response = await axios.post("/create-order", {
                      methodPayment: "paypal",
                      package_id: ShowBuy.id,
                    }, {
                      headers: {
                        Authorization: `Bearer ${res.data.token}`,
                        "Content-Type": "application/json"
                      }
                    });
                    
                    const { data: responseData } = response;
                
                    if (responseData.id) {
                      return responseData.id;
                    } else {
                      const errorDetail = responseData?.details?.[0];
                      const errorMessage = errorDetail
                        ? `${errorDetail.issue} ${errorDetail.description} (${responseData.debug_id})`
                        : JSON.stringify(responseData);
                
                      throw new Error(errorMessage);
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
                onApprove={async (data, actions) => {
                  try {
                    const response = await axiosPrivate.post(
                      `/orders/${data.orderID}/capture`,
                    );
                    const { data: datas } = response
                    const errorDetail = datas?.details?.[0];
      
                    if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                      return actions.restart();
                    } else if (errorDetail) {
                      // (2) Other non-recoverable errors -> Show a failure message
                      throw new Error(
                        `${errorDetail.description} (${datas.debug_id})`,
                      );
                    } else {
                      const transaction = datas.purchase_units[0].payments.captures[0];
                      setMessage({ value: `Transaction completed successfully.`, type: 'success' });
                    }
                  } catch (error) {
                    console.log(error)
                  }
                }}
                onCancel={async (data, actions) => {
                  try {
                    const response = await axiosPrivate.post(
                      `/orders/${data.orderID}/capture`,
                    );
                    const { data: datas } = response
                    const errorDetail = datas?.details?.[0];
                      console.log(errorDetail)
                    if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                      // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                      // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                      return actions.restart();
                    } else if (errorDetail) {
                      console.log(errorDetail)
                      // (2) Other non-recoverable errors -> Show a failure message
                      throw new Error(
                        `${errorDetail.description} (${datas.debug_id})`,
                      );
                    } else {
                        setMessage({ value: `Transaction canceled successfully.`, type: 'success' });
                    }
                  } catch (error) {
                    console.log(error)
                  }
                }}
              />
            </PayPalScriptProvider>
            {message.value && message.type && 
            <div className={`alert alert-${message.type === "error" ? "danger" : message.type}`} role="alert">
            {message.value}
            </div>
            }
            </>,
              isPackage: true,
              isPaypal: true
            }
          ]} />
        {/* <Button variant="secondary">Pay using Crypto</Button> */}
        </div>
        }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
          Cancel
          </Button>
          {methodPayment === "crypto" &&
          <Button variant="secondary" onClick={createOrder}>
          <span>{isLoading ? 
           "Loading..."
          : errorValue.length ? errorValue 
          : "Place order" }
          </span>
        </Button>
        }
        </Modal.Footer>
      </Modal>
  );
};
